<script lang="ts" setup>

const { isLogin } = useAuthStore();
const localePath = useLocalePath();
const router = useRouter();

const onClick = () => {
    console.log('onclick');

    if (isLogin) {
        // 清空 matObj
        const matObj = useState('matObj');
        matObj.value = null;
        router.push(localePath('/face-swap'));
    } else {
        router.push(localePath('/login/google?redirect=/face-swap'));
    }
};
</script>
<template>
    <!-- Button -->
    <nuxt-link @click="onClick">
        <button
            class="group gap-2 inline-flex items-center justify-center rounded-full py-4 pl-6 pr-4 text-base font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-tr from-orange-600 to-amber-500 text-white hover:opacity-90 active:opacity-80 duration-300">
            <span>Faceswap Now</span>
            <div class="relative right-0 group-hover:translate-x-1 w-5 duration-200">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                    class="arrow-right">
                    <path class="opacity-0 group-hover:opacity-100 duration-200" d="M5 12h14"></path>
                    <path d="m12 5 7 7-7 7"></path>
                </svg>
            </div>
        </button>
    </nuxt-link>
    <!-- Button END -->
</template>
